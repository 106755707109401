import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'
import { type Locale, useRouter } from 'next/router'

import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import { Box } from '@mui/material'
import { HttpStatusCode, isAxiosError } from 'axios'

import ProductDetailsPage from '@/components/ProductDetailsPage/Page'
import { PageLayout } from '@/layouts/PageLayout'
import type { ProductDetailsNextPageProps } from '@/types'
import { endpoints } from '@/utils/backend/api'
import { getCanonicalHref } from '@/utils/backend/getCanonicalHref'
import { getCommonServerProps } from '@/utils/backend/getCommonServerProps'
import { getProductDetailProperties } from '@/utils/frontend/analytics/propertiesHelpers'
import { useProductDetailPageView } from '@/utils/frontend/analytics/usePageView'
import { useTrackScrollPosition } from '@/utils/frontend/analytics/useTrackScrollPosition'
import { extractIdFromSlug, extractProductId } from '@/utils/plain/extractIdFromSlug'
import { isEmpty } from '@/utils/plain/isEmpty'
import { isValidProductId } from '@/utils/plain/product.utils'

type PageProps = ProductDetailsNextPageProps & {
  appsConfig: IAppsConfig
}

export const PDP: NextPage<PageProps> = (props) => {
  const { canonicalHref, footerEntries, headerEntries, appsConfig, product } = props

  const { asPath } = useRouter()
  const productId = extractProductId(asPath)

  const { productNr } = product

  if (productNr !== productId) {
    logger.error('productId and productNr do not match FE', {
      asPath,
      product,
    })
  }

  useProductDetailPageView(product)
  useTrackScrollPosition(getProductDetailProperties(product))

  return (
    <Box>
      <Head>
        <link rel="canonical" href={canonicalHref} />
      </Head>
      <PageLayout
        footerEntries={footerEntries}
        headerEntries={headerEntries}
        appsConfig={appsConfig}
      >
        <ProductDetailsPage product={product} />
      </PageLayout>
    </Box>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const productPath = ctx.resolvedUrl.split('/').at(-2) ?? ''
  const locale = ctx?.locale as Locale

  const productSlug = ctx.params?.slug as string

  if (isEmpty(productSlug)) {
    return {
      // 'product-slug-missing' error
      notFound: true,
    }
  }

  const productId = extractIdFromSlug(productSlug)
  if (isEmpty(productId) || !isValidProductId(productId)) {
    return {
      // 'product-id-missing' error or wrong product id
      notFound: true,
    }
  }

  try {
    const { data: product } = await endpoints.getProduct(locale, productId)

    if (product.productNr !== productId) {
      logger.error('productId and productNr do not match', {
        request: ctx,
        productPath,
        productId,
        productResponse: product,
      })
    }

    const canonicalHref = getCanonicalHref(locale!, productPath, product.slug)

    return {
      props: {
        key: productId,
        product,
        ...(await getCommonServerProps({ locale, canonicalHrefPath: canonicalHref })),
        canonicalHref,
      },
    }
  } catch (error) {
    if (isAxiosError(error)) {
      const status = error.response?.status

      if (status === HttpStatusCode.NotFound) {
        return {
          // 'product-not-found' error
          notFound: true,
        }
      }
    }
    logger.error('unexpected error in getProduct', { error })
    throw error
  }
}

export default PDP
